import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Util from '../../util';
import { useTranslation } from 'react-i18next';

export const TablePayment = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { type, data, loading, rowClick } = props;

  const buildContainerClassname = () => {
    let classname = 'table-payment';

    if (type) {
      classname += ' ' + type;
    }
    return classname;
  };

  console.log('Board', data);

  const renderType = (data) => {
    let text = '';

    return Util.Form.GetLabel('region', data.serviceRegion) + ' ' + Util.Form.GetLabel('price_id', data.type);
    switch (data.serviceRegion) {
      case 'domestic': {
        switch (data.type) {
          case 'ONE_YEAR': {
            text = t('table.payment.data.domestic_1');
            break;
          }
          case 'TWO_YEAR': {
            text = t('table.payment.data.domestic_2');
            break;
          }
          case 'ALL_ONE_YEAR': {
            text = t('table.payment.data.all_1');
            break;
          }
          case 'ALL_TWO_YEAR': {
            text = t('table.payment.data.all_2');
            break;
          }
          case 'THREE_MONTH': {
            text = t('table.payment.data.domestic_3');
            break;
          }
          case 'ALL_THREE_MONTH': {
            text = t('table.payment.data.all_3');
            break;
          }
          case 'FIVE_YEAR': {
            text = t('table.payment.data.domestic_5');
            break;
          }
          case 'ALL_FIVE_YEAR': {
            text = t('table.payment.data.all_5');
            break;
          }
          default: {
            text = 'none';
            break;
          }
        }
        break;
      }
      case 'usa': {
        switch (data.type) {
          case 'ONE_YEAR': {
            text = t('table.payment.data.usa_1');
            break;
          }
          case 'TWO_YEAR': {
            text = t('table.payment.data.usa_2');
            break;
          }
          case 'ALL_ONE_YEAR': {
            text = t('table.payment.data.all_1');
            break;
          }
          case 'ALL_TWO_YEAR': {
            text = t('table.payment.data.all_2');
            break;
          }
          case 'THREE_MONTH': {
            text = t('table.payment.data.usa_3');
            break;
          }
          case 'ALL_THREE_MONTH': {
            text = t('table.payment.data.all_3');
            break;
          }
          case 'FIVE_YEAR': {
            text = t('table.payment.data.usa_5');
            break;
          }
          case 'ALL_FIVE_YEAR': {
            text = t('table.payment.data.all_5');
            break;
          }
          default: {
            text = 'none';
            break;
          }
        }
        break;
      }

      default: {
        text = 'none';
        break;
      }
    }

    if (data.rewardDate) {
      text = text + '(' + Util.Form.getDayByUnixTime(data.rewardDate) + '일)';
    }

    return text;
  };

  if (loading) {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }

  if (data && data.length > 0) {
    return (
      <article className={buildContainerClassname()}>
        <ul className='table-header'>
          <li>
            <span>{t('table.payment.header.type')}</span>
          </li>
          <li>
            <span>{t('table.payment.header.method')}</span>
          </li>
          <li>
            <span>{t('table.payment.header.price')}</span>
          </li>
          <li>
            <span>{t('table.payment.header.status')}</span>
          </li>
          <li>
            <span>{t('table.payment.header.recommend')}</span>
          </li>
          <li>
            <span>{t('table.payment.header.date')}</span>
          </li>
        </ul>
        {data.map((item, index) => {
          return (
            <ul className='table-data' key={'board-data-' + index}>
              <li>{renderType(item)}</li>

              <li>
                <span>{item.method === 'card' ? t('table.payment.data.card') : t('table.payment.data.point')}</span>
              </li>
              <li>
                {Util.Form.NumberFormatter(item.amount)}{' '}
                {item.method === 'card' ? t('table.payment.data.dollar') : t('table.payment.data.point')}
              </li>
              <li>{t('table.payment.data.paid_success')}</li>
              <li>{item.recommendId ? item.recommendId : item.recommendCode}</li>
              <li>
                <span>{Util.Form.getYYYYMMDDByUnix(item.statusUpdatedAt)}</span>
              </li>
            </ul>
          );
        })}
      </article>
    );
  } else if (data && data.length === 0) {
    return (
      <article className={buildContainerClassname()}>
        {
          <div className='empty'>
            <span>{t('system.label.no_result')}</span>
          </div>
        }
      </article>
    );
  } else {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }
};
