import React, { useEffect, useState, useContext, userEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext, SnackbarContext } from '../../contexts';
import { useInput, usePagination } from '../../hooks';
import API from '../../api';
import Util from '../../util';
import { FormInput, Button, Paging, Board, TablePayment } from '../../components';
import { useTranslation } from 'react-i18next';

export const MypageMembershipPage = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(1, 10);
  const [loading, setLoading] = useState(false);
  const [paymentSubscription, setPaymentSubscription] = useState(null);

  console.log('userInfo', userInfo);
  console.log('paymentSubscription', paymentSubscription);

  useEffect(() => {
    refreshUserInfo();
  }, []);

  useEffect(() => {
    requsetList();
  }, [page]);

  const requsetList = (init_page) => {
    setLoading(true);
    let params = {
      page: init_page ? init_page : page,
      limit: 10,
    };

    API.Member.GetPayment(params).then(
      (res) => {
        let result = res.data;
        console.log('Member.GetPayment', result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
        setTotalCount(result.meta.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    API.Member.GetPaymentSubscription().then(
      (res) => {
        let result = res.data;
        console.log('Member.GetPaymentSubscription', result);
        setPaymentSubscription(result);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetGetPaymentSubscription = () => {
    API.Member.GetPaymentSubscription().then(
      (res) => {
        let result = res.data;
        console.log('Member.GetPaymentSubscription', result);
        setPaymentSubscription(result);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const updateCancelAtPeriodEnd = (array, no, newCancelAtPeriodEnd) => {
    // 주어진 배열에서 'no' 값에 해당하는 항목을 찾아 'cancelAtPeriodEnd' 값을 업데이트
    const updatedArray = array.map((item) => {
      if (item.no === no) {
        return { ...item, cancelAtPeriodEnd: newCancelAtPeriodEnd };
      }
      return item;
    });
    return updatedArray;
  };

  const requsetCancel = (no) => {
    API.Member.PutPaymentSubscriptionCancel(no).then(
      (res) => {
        let result = res.data;
        console.log('Member.PutPaymentSubscriptionCancel', result);

        updateSnackbar({
          type: 'success',
          open: true,
          message: t('mypage.membership_subscription.cancel_success_message'),
        });
        let newPaymentSubscription = updateCancelAtPeriodEnd(paymentSubscription, no, result.cancelAtPeriodEnd);
        setPaymentSubscription(newPaymentSubscription);
        setTimeout(() => {
          requsetGetPaymentSubscription();
        }, 2000);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetReactivate = (no) => {
    API.Member.PutPaymentSubscriptionReactivate(no).then(
      (res) => {
        let result = res.data;
        console.log('Member.PutPaymentSubscriptionCancel', result);
        updateSnackbar({
          type: 'success',
          open: true,
          message: t('mypage.membership_subscription.reactivate_success_message'),
        });
        let newPaymentSubscription = updateCancelAtPeriodEnd(paymentSubscription, no, result.cancelAtPeriodEnd);
        setPaymentSubscription(newPaymentSubscription);
        setTimeout(() => {
          requsetGetPaymentSubscription();
        }, 2000);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };
  if (!userInfo) {
    return null;
  }

  return (
    <article className='mypage-membership-page'>
      <section className='title__box'>
        <h3>{t('mypage.membership_status.title')}</h3>
      </section>
      <section className='membership-status__box'>
        <ul>
          <li>
            <header>
              <span>{t('mypage.membership_status.kr_label')}: </span>
            </header>
            <div className='value__box'>
              <span>{userInfo.vipDate ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDate) : '-'}</span>
            </div>
          </li>
          <li>
            <header>
              <span>{t('mypage.membership_status.en_label')} : </span>
            </header>
            <div className='value__box'>
              <span>{userInfo.vipDateUsa ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDateUsa) : '-'}</span>
            </div>
          </li>
          <li>
            <header>
              <span>{t('mypage.membership_status.vnm_label')} : </span>
            </header>
            <div className='value__box'>
              <span>{userInfo.vipDateVnm ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDateVnm) : '-'}</span>
            </div>
          </li>
          <li>
            <header>
              <span>{t('mypage.membership_status.jpn_label')} : </span>
            </header>
            <div className='value__box'>
              <span>{userInfo.vipDateJpn ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDateJpn) : '-'}</span>
            </div>
          </li>
          <li>
            <header>
              <span>{t('mypage.membership_status.all_label')} : </span>
            </header>
            <div className='value__box'>
              <span>{userInfo.vipDateAll ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDateAll) : '-'}</span>
            </div>
          </li>
        </ul>
      </section>
      <section className='title__box' style={{ flexDirection: 'column' }}>
        <h3>{t('mypage.membership_subscription.title')}</h3>
        {paymentSubscription && paymentSubscription.length > 0 && <p>※ {t('mypage.membership_subscription.desc')}</p>}
      </section>
      <section className='table__box' style={{ marginBottom: 48 }}>
        <div className='membership_subscription__container'>
          <ul>
            {paymentSubscription &&
              paymentSubscription.map((item, index) => {
                return (
                  <li>
                    <div>
                      <label>{t('mypage.membership_subscription.product_label')}</label>
                      <span>
                        {Util.Form.GetLabel('region', item.serviceRegion)}{' '}
                        {Util.Form.GetLabel('price_id', item.requestId)}
                      </span>
                    </div>
                    <div>
                      <label>{t('mypage.membership_subscription.range_label')}</label>
                      <span>
                        {Util.Form.getYYYYMMDDByUnix(item.periodStart)} ~ {Util.Form.getYYYYMMDDByUnix(item.periodEnd)}
                      </span>
                    </div>
                    {!item.cancelAtPeriodEnd && (
                      <Button
                        type='header line'
                        label={t('mypage.membership_subscription.cancel_button')}
                        onClick={() => {
                          requsetCancel(item.no);
                        }}
                        style={{ marginRight: 16 }}
                      ></Button>
                    )}
                    {item.cancelAtPeriodEnd && (
                      <Button
                        type='header'
                        label={t('mypage.membership_subscription.reactivate_button')}
                        onClick={() => {
                          requsetReactivate(item.no);
                        }}
                        style={{ marginRight: 16 }}
                      ></Button>
                    )}
                  </li>
                );
              })}
            {paymentSubscription && paymentSubscription.length === 0 && (
              <span>{t('mypage.membership_subscription.no_items')}</span>
            )}
          </ul>
        </div>
      </section>
      <section className='title__box'>
        <h3>{t('mypage.membership_payment.title')}</h3>
      </section>
      <section className='table__box'>
        <TablePayment data={list} loading={loading}></TablePayment>
      </section>

      <section className='page__box'>
        <Paging page={page} totalPage={totalPage} onChange={onPageChange}></Paging>
      </section>
    </article>
  );
};
