import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import TextTruncate from 'react-text-truncate'; // recommend
import Util from '../../util';
import { useTranslation } from 'react-i18next';

export const Board = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴
  const history = useHistory();
  const { type, data, loading, rowClick } = props;

  const buildContainerClassname = () => {
    let classname = 'board';

    if (type) {
      classname += ' ' + type;
    }
    return classname;
  };

  console.log('Board', data);

  if (loading) {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }

  if (data && data.length > 0) {
    return (
      <article className={buildContainerClassname()}>
        <ul className='board-header'>
          <li>
            <span>{t('board.header.no')}</span>
          </li>
          <li>
            <span>{t('board.header.title')}</span>
          </li>
          {type === 'free' && (
            <li>
              <span>{t('board.header.writer')}</span>
            </li>
          )}
          <li>
            <span>{t('board.header.write_date')}</span>
          </li>
          <li>
            <span>{t('board.header.view')}</span>
          </li>
        </ul>
        {data.map((item, index) => {
          return (
            <ul
              className='board-data'
              key={'board-data-' + index}
              onClick={() => {
                rowClick(index);
              }}
            >
              <li>
                <span>{item.no}</span>
              </li>
              <li>
                <TextTruncate line={1} element='span' truncateText='…' text={item.title} />
              </li>

              {type === 'free' && (
                <li>
                  <span>{item.memberId}</span>
                </li>
              )}
              <li>
                <span>{Util.Form.getYYYYMMDDByUnix(item.createdAt)}</span>
              </li>
              <li>
                <span>{Util.Form.NumberFormatter(item.hit)}</span>
              </li>
            </ul>
          );
        })}
      </article>
    );
  } else if (data && data.length === 0) {
    return (
      <article className={buildContainerClassname()}>
        {
          <div className='empty'>
            <span>{t('system.label.no_result')}</span>
          </div>
        }
      </article>
    );
  } else {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }
};
