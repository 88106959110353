import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Util from '../../util';
import { useTranslation } from 'react-i18next';

export const TableCalculate = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { type, data, loading, rowClick } = props;

  const buildContainerClassname = () => {
    let classname = 'table-calculate';

    if (type) {
      classname += ' ' + type;
    }
    return classname;
  };

  console.log('Board', data);

  const renderType = (data) => {
    switch (data.status) {
      case 'Request': {
        return t('table.calculate.data.Request');
      }
      case 'Complete': {
        return t('table.calculate.data.Complete');
      }
      case 'Reject': {
        return t('table.calculate.data.Reject');
      }

      default: {
        return 'none';
      }
    }
  };

  const renderAmount = (data) => {
    // {Util.Form.NumberFormatter(item.amount)}

    return Util.Form.formatNumber(data.amount);
  };

  if (loading) {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }

  if (data && data.length > 0) {
    return (
      <article className={buildContainerClassname()}>
        <ul className='table-header'>
          <li>
            <span>{t('table.calculate.header.type')}</span>
          </li>
          <li>
            <span>{t('table.calculate.header.price')}</span>
          </li>
          <li>
            <span>{t('table.calculate.header.accountName')}</span>
          </li>
          <li>
            <span>{t('table.calculate.header.bank')}</span>
          </li>
          <li>
            <span>{t('table.calculate.header.account')}</span>
          </li>
          <li>
            <span>{t('table.calculate.header.date')}</span>
          </li>
        </ul>
        {data.map((item, index) => {
          return (
            <ul className={'table-data ' + item.status} key={'board-data-' + index}>
              <li>
                <span>{renderType(item)}</span>
              </li>
              <li>{renderAmount(item)}</li>
              <li>
                <span>{item.accountName}</span>
              </li>
              <li>
                <span>{item.bank}</span>
              </li>
              <li>
                <span>{item.account}</span>
              </li>
              <li>
                <span>{Util.Form.getYYYYMMDDByUnix(item.createdAt)}</span>
              </li>
            </ul>
          );
        })}
      </article>
    );
  } else if (data && data.length === 0) {
    return (
      <article className={buildContainerClassname()}>
        {
          <div className='empty'>
            <span>{t('system.label.no_result')}</span>
          </div>
        }
      </article>
    );
  } else {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }
};
