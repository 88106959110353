import React, { useEffect, useContext, useState } from 'react';
import { UserContext, SnackbarContext, ModalContext } from '../contexts';
import API from '../api';
import Util from '../util';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: 100,
  },
};

export const MembershipPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { userInfo, clearToken } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const [info, setInfo] = useState(null);
  const [region, setRegion] = useState('all'); // all or domestic,usa,...
  const [priceId, setPriceId] = useState('ALL_THREE_MONTH');
  const [year, setYear] = useState(1);
  const [method, setMethod] = useState('dollar');
  const [referral, setReferral] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { updateModalOpen, updateModalData, closeModal } = useContext(ModalContext);
  const [referralInfo, setReferralInfo] = useState(null);
  const [referralCodeInfo, setReferralCodeInfo] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  //modal
  const [open, setOpen] = useState(false);

  console.log('info', info);
  console.log('priceId', priceId);

  useEffect(() => {
    // API.Payment.GetPrice().then(
    //   (res) => {
    //     let result = res.data;
    //     console.log('Payment.GetPrice()', result);
    //     setData(result);
    //   },
    //   (err) => {
    //     updateSnackbar({
    //       type: 'error',
    //       open: true,
    //       message: err.data.message,
    //     });
    //   },
    // );

    API.Payment.GetPriceInfo().then(
      (res) => {
        let result = res.data;
        console.log('Payment.GetPriceInfo()', result);
        setInfo(result);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  }, []);

  useEffect(() => {
    if (region === 'all') {
      setPriceId('ALL_THREE_MONTH');
    } else {
      setPriceId('THREE_MONTH');
    }
  }, [region]);

  useEffect(() => {
    if (userInfo) {
      API.Payment.GetPaymentSubscription().then(
        (res) => {
          let result = res.data;
          console.log('Payment.GetPaymentSubscription()', result);
          setSubscriptionInfo(result);
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
    if (userInfo && userInfo.recommendCode) {
      console.log('userInfo.recommendCode', userInfo.recommendCode);
      setReferralCode(userInfo.recommendCode);
      API.Common.GetUserIdCode(userInfo.recommendCode).then(
        (res) => {
          console.log('GetUserIdCode res', res);
          if (res.data) {
            setReferralCodeInfo(res.data);
          } else {
            setReferralCodeInfo(null);
          }
        },
        (err) => {
          console.log('GetUserIdCode err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (referral) {
      setReferralInfo(null);
    } else {
      setReferralInfo(null);
    }
  }, [referral]);

  useEffect(() => {
    if (referralCode) {
      setReferralCodeInfo(null);
    } else {
      setReferralCodeInfo(null);
    }
  }, [referralCode]);

  const requestReferralCheck = () => {
    console.log('requestReferralCheck');

    if (referral) {
      API.Common.GetUserId(referral).then(
        (res) => {
          console.log('res', res);
          if (res.data.member) {
            setReferralInfo(res.data.member);
            updateSnackbar({
              type: 'info',
              open: true,
              message: t('membership.alert.referral_success'),
            });
          } else {
            setReferralInfo(res.data.member);

            updateSnackbar({
              type: 'error',
              open: true,
              message: t('membership.alert.referral_error'),
            });
          }
        },
        (err) => {
          console.log('err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('membership.alert.referral_input_none'),
      });
    }
  };

  const requestReferralCodeCheck = () => {
    console.log('requestReferralCodeCheck');

    if (referralCode) {
      API.Common.GetUserIdCode(referralCode).then(
        (res) => {
          console.log('GetUserIdCode res', res);
          if (res.data) {
            setReferralCodeInfo(res.data);
            updateSnackbar({
              type: 'info',
              open: true,
              message: t('membership.alert.referral_success'),
            });
          } else {
            setReferralCodeInfo(null);
            updateSnackbar({
              type: 'error',
              open: true,
              message: t('membership.alert.referral_code_error'),
            });
          }
        },
        (err) => {
          console.log('GetUserIdCode err', err);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('membership.alert.referral_code_input_none'),
      });
    }
  };

  useEffect(() => {
    if (method === 'point') {
      setReferral('');
    }
  });

  const getPriceInfo = () => {
    if (region === 'all') {
      for (let i in info.allInOne.price) {
        let target = info.allInOne.price[i];
        if (target.id === priceId) {
          if (method === 'point') {
            return (
              <span>
                {target.pointAmount} {t('membership.requset.unit.point')} <em>/</em>{' '}
                {Util.Form.GetLabel('price_id', target.id)}
              </span>
            );
          }
          if (method === 'dollar') {
            return (
              <span>
                {target.amount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                {Util.Form.GetLabel('price_id', target.id)}
              </span>
            );
          }
        }
      }
    } else {
      let targetCountry = null;
      for (let i in info.country) {
        let target = info.country[i];
        if (target.region === region) {
          targetCountry = target;
        }
      }

      if (targetCountry) {
        for (let i in targetCountry.price) {
          let target = targetCountry.price[i];
          if (target.id === priceId) {
            if (method === 'point') {
              return (
                <span>
                  {target.pointAmount} {t('membership.requset.unit.point')} <em>/</em>{' '}
                  {Util.Form.GetLabel('price_id', target.id)}
                </span>
              );
            }
            if (method === 'dollar') {
              return (
                <span>
                  {target.amount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                  {Util.Form.GetLabel('price_id', target.id)}
                </span>
              );
            }
          }
        }
      }
    }
  };
  const getPrice = () => {
    switch (region) {
      case 'domestic': {
        switch (year) {
          case 1: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.domestic.oneYearDomesticAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.1_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.domestic.oneYearDomesticPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.1_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 2: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.domestic.twoYearDomesticAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.2_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.domestic.twoYearDomesticPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.2_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 3: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.domestic.threeMonthDomesticAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.3_month')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.domestic.threeMonthDomesticPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.3_month')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 5: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.domestic.fiveYearDomesticAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.5_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.domestic.fiveYearDomesticPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.5_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          default: {
            return 'none';
          }
        }
      }
      case 'usa': {
        switch (year) {
          case 1: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.usa.oneYearUsaAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.1_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.usa.oneYearUsaPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.1_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 2: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.usa.twoYearUsaAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.2_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.usa.twoYearUsaPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.2_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 3: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.usa.threeMonthUsaAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.3_month')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.usa.threeMonthUsaPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.3_month')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 5: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.region.usa.fiveYearUsaAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.5_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.region.usa.fiveYearUsaPointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.5_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          default: {
            return 'none';
          }
        }
      }
      case 'all': {
        switch (year) {
          case 1: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.all.oneYearAllInOneAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.1_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.all.oneYearAllInOnePointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.1_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 2: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.all.twoYearAllInOneAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.2_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.all.twoYearAllInOnePointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.2_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 3: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.all.threeMonthAllInOneAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.3_month')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.all.threeMonthAllInOnePointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.3_month')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          case 5: {
            switch (method) {
              case 'dollar': {
                return (
                  <span>
                    {data.all.fiveYearAllInOneAmount} {t('membership.requset.unit.dollar')} <em>/</em>{' '}
                    {t('membership.request.label.5_year')}
                  </span>
                );
              }
              case 'point': {
                return (
                  <span>
                    {Util.Form.NumberFormatter(data.all.fiveYearAllInOnePointAmount)}{' '}
                    {t('membership.requset.unit.point')} <em>/</em> {t('membership.request.label.5_year')}
                  </span>
                );
              }
              default: {
                return 'none';
              }
            }
          }
          default: {
            return 'none';
          }
        }
      }
      default: {
        return 'none';
      }
    }
  };

  const requestPay = async () => {
    console.log('requestPay');
    console.log('requestPay method', method);
    console.log('requestPay subscriptionInfo', subscriptionInfo);

    if (!userInfo) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('system.alert.no_login'),
      });
      history.push('/login');
      return;
    }

    if (method === 'dollar' && referral) {
      if (!referralInfo) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: t('membership.alert.referral_no_check'),
        });
        return;
      }
    }

    if (method === 'dollar' && referralCode) {
      if (!referralCodeInfo) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: t('membership.alert.referral_no_check'),
        });
        return;
      }
    }

    if (referral && referralCode) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('membership.alert.referral_duplicated'),
      });
      return;
    }

    if (method === 'point') {
      console.log('point logic');

      updateModalData({
        type: 'confirm',
        title: t('membership.confirm.point_title'),
        content: t('membership.confirm.point_desc'),
        onConfirm: () => {
          console.log('work');
          let body = {
            serviceRegion: region,
            requestId: priceId,
          };
          API.Payment.PostPoint(body).then(
            (res) => {
              let result = res.data;
              console.log('Payment.PostPoint', result);
              updateSnackbar({
                type: 'success',
                open: true,
                title: '포인트 결제 성공',
              });
              closeModal();
            },
            (err) => {
              updateSnackbar({
                type: 'error',
                open: true,
                message: err.data.message,
              });
            },
          );
        },
      });
      updateModalOpen(true);
    }

    if (method === 'dollar') {
      console.log('dollar logic');

      // 구독 결제시 판단 필요

      console.log('priceId', priceId);

      // RECURRING_ALL_ONE_YEAR 올인원
      // RECURRING_ONE_YEAR 국가 region

      if (Util.Form.IsAvailablePayment(subscriptionInfo, priceId, region)) {
        let body = {
          serviceRegion: region,
          requestId: priceId,
          successUrl: window.location.origin + '/mypage/membership',
          cancelUrl: window.location.origin + '/membership',
          recommendId: referral,
          recommendCode: referralCode,
        };

        API.Payment.GetStripeUrl(body).then(
          (res) => {
            let result = res.data;
            console.log('Payment.GetPrice()', result);
            window.open(result.url, '_blank');
          },
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
      } else {
        updateSnackbar({
          type: 'error',
          open: true,
          message: '이미 구독한 상품입니다.',
        });
      }
    }
  };
  const requestPayOrigin = async () => {
    console.log('requestPay');

    if (!userInfo) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('system.alert.no_login'),
      });
      history.push('/login');
      return;
    }

    if (method === 'dollar' && referral) {
      if (!referralInfo) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: t('membership.alert.referral_no_check'),
        });
        return;
      }
    }

    if (method === 'dollar' && referralCode) {
      if (!referralCodeInfo) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: t('membership.alert.referral_no_check'),
        });
        return;
      }
    }

    if (referral && referralCode) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('membership.alert.referral_duplicated'),
      });
      return;
    }

    switch (region) {
      case 'domestic': {
        switch (year) {
          case 1: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'ONE_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };

                // let body = {
                //   serviceRegion: "test",
                //   requestId: "ONE_YEAR",
                //   successUrl: window.location.origin + "/mypage/membership",
                //   cancelUrl: window.location.origin + "/membership",
                //   recommendId: referral,
                // };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                console.log('work');
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'ONE_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);
                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 2: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'TWO_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'TWO_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);

                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 3: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'THREE_MONTH',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'THREE_MONTH',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);

                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 5: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'FIVE_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'FIVE_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);

                return;
              }
              default: {
                return 'none';
              }
            }
          }
          default: {
            return 'none';
          }
        }
      }
      case 'usa': {
        switch (year) {
          case 1: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'usa',
                  requestId: 'ONE_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'usa',
                      requestId: 'ONE_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);
                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 2: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'usa',
                  requestId: 'TWO_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'usa',
                      requestId: 'TWO_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);
                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 3: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'usa',
                  requestId: 'THREE_MONTH',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'usa',
                      requestId: 'THREE_MONTH',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);
                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 5: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'usa',
                  requestId: 'FIVE_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'usa',
                      requestId: 'FIVE_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);
                return;
              }
              default: {
                return 'none';
              }
            }
          }
          default: {
            return 'none';
          }
        }
      }
      case 'all': {
        switch (year) {
          case 1: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'ALL_ONE_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );

                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'ALL_ONE_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);

                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 2: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'ALL_TWO_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'ALL_TWO_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);

                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 3: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'ALL_THREE_MONTH',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'ALL_THREE_MONTH',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);

                return;
              }
              default: {
                return 'none';
              }
            }
          }
          case 5: {
            switch (method) {
              case 'dollar': {
                let body = {
                  serviceRegion: 'domestic',
                  requestId: 'ALL_FIVE_YEAR',
                  successUrl: window.location.origin + '/mypage/membership',
                  cancelUrl: window.location.origin + '/membership',
                  recommendId: referral,
                  recommendCode: referralCode,
                };
                API.Payment.GetStripeUrl(body).then(
                  (res) => {
                    let result = res.data;
                    console.log('Payment.GetPrice()', result);
                    window.open(result.url, '_blank');
                  },
                  (err) => {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: err.data.message,
                    });
                  },
                );
                return;
              }
              case 'point': {
                updateModalData({
                  type: 'confirm',
                  title: t('membership.confirm.point_title'),
                  content: t('membership.confirm.point_desc'),
                  onConfirm: () => {
                    console.log('work');
                    let body = {
                      serviceRegion: 'domestic',
                      requestId: 'ALL_FIVE_YEAR',
                    };
                    API.Payment.PostPoint(body).then(
                      (res) => {
                        let result = res.data;
                        console.log('Payment.PostPoint', result);
                        updateSnackbar({
                          type: 'success',
                          open: true,
                          title: t('membership.alert.point_success'),
                        });
                        closeModal();
                      },
                      (err) => {
                        updateSnackbar({
                          type: 'error',
                          open: true,
                          message: err.data.message,
                        });
                      },
                    );
                  },
                });
                updateModalOpen(true);

                return;
              }
              default: {
                return 'none';
              }
            }
          }
          default: {
          }
        }
      }
      default: {
        return 'none';
      }
    }
  };

  const isPayDisabeld = () => {
    if (subscriptionInfo) {
      if (Util.Form.IsAvailablePayment(subscriptionInfo, priceId, region)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  if (!info) {
    return null;
  }

  if (!info) {
    return (
      <article className='membership-page'>
        <div className='content__wrapper'>
          <div className='loader big color'></div>
        </div>
      </article>
    );
  }

  console.log('region', region);
  console.log('priceId', priceId);
  console.log('method', method);

  const hasOneYear = (text) => {
    return text.includes('ONE_YEAR');
  };

  return (
    <article className='membership-page'>
      <div className='content__wrapper'>
        <section className='membership-request-line__box'>
          <header>
            <h3>{t('membership.request.title')}</h3>
            <p>{t('membership.request.description')}</p>
          </header>
        </section>
        <section className='membership-select__box'>
          <header>
            <span>{t('membership.request.label.region')}</span>
          </header>
          <ul>
            <li
              className={region !== 'all' ? 'on' : ''}
              onClick={() => {
                setOpen(true);
              }}
            >
              <b>{region === 'all' ? '국가 선택' : Util.Form.GetLabel('region', region)}</b>
            </li>
            <li
              className={region === 'all' ? 'on' : ''}
              onClick={() => {
                setRegion('all');
              }}
            >
              <b>{t('membership.request.label.all')}</b>
            </li>
          </ul>
        </section>
        <section className='membership-select__box year'>
          <header>
            <span>{t('membership.request.label.range')}</span>
          </header>
          <ul>
            {region === 'all' &&
              info.allInOne.price.map((item, index) => {
                if (index === 0 || index === 4) {
                  return (
                    <li
                      className={priceId === item.id ? 'on' : ''}
                      onClick={() => {
                        setPriceId(item.id);
                      }}
                    >
                      <b>{Util.Form.GetLabel('price_id', item.id)}</b>
                    </li>
                  );
                }
              })}
            {region !== 'all' &&
              info.country[0].price.map((item, index) => {
                if (index === 0 || index === 4) {
                  return (
                    <li
                      className={priceId === item.id ? 'on' : ''}
                      onClick={() => {
                        setPriceId(item.id);
                      }}
                    >
                      <b>{Util.Form.GetLabel('price_id', item.id)}</b>
                    </li>
                  );
                }
              })}
          </ul>
        </section>

        {/* <section className='membership-select__box'>
          <header>
            <span>{t('membership.request.label.payment_method')}</span>
          </header>
          <ul>
            <li
              className={method === 'dollar' ? 'on' : ''}
              onClick={() => {
                setMethod('dollar');
              }}
            >
              <b>{t('membership.request.label.payment_card')}</b>
            </li>
             <li
              className={method === 'point' ? 'on' : ''}
              onClick={() => {
                setMethod('point');
              }}
            >
              <b>{t('membership.request.label.payment_point')}</b>
            </li> 
          </ul>
        </section> */}

        {/* <section className='membership-select__box'>
          <header>
            <span>{t('membership.request.label.recommend')}</span>
          </header>
          <div className='input__box'>
            <input
              placeholder={t('membership.request.placeholder.recommend')}
              disabled={method === 'point'}
              value={referral}
              onChange={(e) => {
                setReferral(e.target.value);
              }}
            ></input>
            <button className='check-button' onClick={requestReferralCheck}>
              {t('membership.request.button.recommend')}
            </button>
          </div>

          {referralInfo && (
            <div className='member-info__box'>
              <ul>
                <li>
                  <span>
                    {t('membership.request.label.recommend_name')} : <b>{Util.Form.masking(referralInfo.name)}</b>
                  </span>
                </li>
                <li>
                  <span>
                    {t('membership.request.label.recommend_nick')} : <b>{referralInfo.nick}</b>
                  </span>
                </li>
              </ul>
            </div>
          )}
        </section> */}

        {/* <section className='membership-select__box'>
          <header>
            <span>{t('membership.request.label.recommend_code')}</span>
          </header>
          <div className='input__box'>
            <input
              placeholder={t('membership.request.placeholder.recommend_code')}
              disabled={method === 'point'}
              value={referralCode}
              onChange={(e) => {
                setReferralCode(e.target.value);
              }}
            ></input>
            <button className='check-button' onClick={requestReferralCodeCheck}>
              {t('membership.request.button.recommend_code')}
            </button>
          </div>

          {referralCodeInfo && (
            <div className='member-info__box'>
              <ul>
                <li>
                  <span>
                    {t('membership.request.label.recommend_name')} : <b>{Util.Form.masking(referralCodeInfo.name)}</b>
                  </span>
                </li>
                <li>
                  <span>
                    {t('membership.request.label.recommend_nick')} : <b>{referralCodeInfo.nick}</b>
                  </span>
                </li>
              </ul>
            </div>
          )}
        </section> */}

        <section className='membership-requset__box'>
          <button disabled={isPayDisabeld()} onClick={requestPay}>
            {getPriceInfo()} <span>{t('membership.requset.label.button')}</span>
            {/* {method === 'dollar' && (
              <div className='point-guide__box'>
                <span>{t('membership.requset.point_desc.button')}</span>
              </div>
            )} */}
          </button>
        </section>
        <section className='paln-help__box'>
          <ul>
            <li>
              {hasOneYear(priceId) ? (
                <>
                  <span style={{ color: '#e50914' }}>{t('membership.request.guide.desc.5')}</span>
                </>
              ) : (
                <span style={{ color: '#e50914' }}>{t('membership.request.guide.desc.1')}</span>
              )}
            </li>
            {hasOneYear(priceId) && (
              <li>
                <span style={{ color: '#006ae6' }}>{t('membership.request.guide.desc.6')}</span>
              </li>
            )}
            <li>
              <span>{t('membership.request.guide.desc.2')}</span>
            </li>
            <li>
              <span>{t('membership.request.guide.desc.3')}</span>
            </li>

            <li>
              <span>
                {t('membership.request.guide.desc.4')} :{' '}
                <b
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const el = document.createElement('textarea');
                    el.value = 'quantkingstaff';
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
                    updateSnackbar({
                      type: 'success',
                      open: true,
                      message: t('membership.copy.kakao_id'),
                    });
                  }}
                >
                  quantkingstaff
                </b>
              </span>
            </li>
          </ul>
        </section>

        <section className='membership-guide__box'>
          <header>
            <h3>{t('membership.guide.title')}</h3>
            <p>{t('membership.guide.sub_title')}</p>
          </header>
          <ul>
            <li>
              <h5>{t('membership.guide.item.1.title')}</h5>
              <b>{t('membership.guide.item.1.sub_title')}</b>
              <span>{t('membership.guide.item.1.desc')}</span>
              <div className='youtube__box'>
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/fNb3gDpsIgA'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </div>
            </li>
            <li>
              <h5>{t('membership.guide.item.2.title')}</h5>
              <b>{t('membership.guide.item.2.sub_title')}</b>
              <span>{t('membership.guide.item.2.desc')}</span>

              <div className='youtube__box'>
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/FrPu7Gh237Y'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </div>
            </li>
            <li>
              <h5>{t('membership.guide.item.3.title')}</h5>
              <b>{t('membership.guide.item.3.sub_title')}</b>
              <span>{t('membership.guide.item.3.desc')}</span>

              <div className='youtube__box'>
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/Ix2iNMpIQwE'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </div>
            </li>
            <li>
              <h5>{t('membership.guide.item.4.title')}</h5>
              <b>{t('membership.guide.item.4.sub_title')}</b>
              <span>{t('membership.guide.item.4.desc')}</span>
              <div className='youtube__box'>
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/BgWlzJ0s47Y'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </div>
            </li>
            <li>
              <h5>{t('membership.guide.item.5.title')}</h5>
              <b>{t('membership.guide.item.5.sub_title')}</b>
              <span>{t('membership.guide.item.5.desc')}</span>

              <div className='youtube__box'>
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/BgWlzJ0s47Y'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </div>
            </li>
            <li>
              <h5>{t('membership.guide.item.6.title')}</h5>
              <b>{t('membership.guide.item.6.sub_title')}</b>
              <span>{t('membership.guide.item.6.desc')}</span>
              <div className='youtube__box'>
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/5-Am0o8WT2U'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='region__container'>
          <div className='header__box'>
            <span>국가선택</span>
            <div className='ico__box'>
              <div
                onClick={() => {
                  setOpen(false);
                }}
                className='ico x_mark'
              ></div>
            </div>
          </div>
          <ul>
            {info.country.map((item, index) => {
              return (
                <li
                  onClick={() => {
                    setRegion(item.region);
                    setOpen(false);
                  }}
                >
                  <span>{Util.Form.GetLabel('region', item.region)}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    </article>
  );
};
