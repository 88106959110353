import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Util from '../../util';
import { useTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate'; // recommend

export const TableQna = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { type, data, loading, rowClick } = props;

  const buildContainerClassname = () => {
    let classname = 'table-qna';

    if (type) {
      classname += ' ' + type;
    }
    return classname;
  };

  console.log('Board', data);

  if (loading) {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }

  if (data && data.length > 0) {
    return (
      <article className={buildContainerClassname()}>
        <ul className='table-header'>
          <li>
            <span>{t('table.qna.header.no')}</span>
          </li>
          <li>
            <span>{t('table.qna.header.title')}</span>
          </li>
          <li>
            <span>{t('table.qna.header.date')}</span>
          </li>
          <li>
            <span>{t('table.qna.header.is_answer')}</span>
          </li>
        </ul>
        {data.map((item, index) => {
          return (
            <ul
              className='table-data'
              key={'board-data-' + index}
              onClick={() => {
                rowClick(index);
              }}
            >
              <li>
                <span>{item.no}</span>
              </li>
              <li>
                <TextTruncate line={1} element='span' truncateText='…' text={item.title} />
              </li>
              <li>
                <span>{Util.Form.getYYYYMMDDByUnix(item.createdAt)}</span>
              </li>
              <li>
                <span
                  style={{
                    color: item.answer ? '#005ff0' : '#777',
                  }}
                >
                  {item.answer ? t('table.qna.data.answe_Y') : t('table.qna.data.answe_N')}
                </span>
              </li>
            </ul>
          );
        })}
      </article>
    );
  } else if (data && data.length === 0) {
    return (
      <article className={buildContainerClassname()}>
        {
          <div className='empty'>
            <span>{t('system.label.no_result')}</span>
          </div>
        }
      </article>
    );
  } else {
    return (
      <article className={buildContainerClassname()}>
        <div className='loader big color' />
      </article>
    );
  }
};
