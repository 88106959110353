import queryString from 'query-string';
import moment from 'moment-timezone';
import BigNumber from 'bignumber.js';

const ObjectToParams = (obj) => {
  const serachString = queryString.stringify(obj);
  //default encodeURIComponent
  return '?' + serachString;
};

const getYYYYMMDDByUnix = (unix) => {
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD');
  return temp_moment;
};

const getYYYYMMDDHHMMssByUnix = (unix) => {
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD HH:mm:ss');
  return temp_moment;
};

const NumberFormatter = (value, decimal) => {
  if (!value) {
    return '0';
  }
  if (Number(value) === 0) {
    return '0';
  }
  var parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts.length === 1) {
    return parts[0];
  }
  if (decimal) {
    let decimal_value = parts[1];
    console.log('decimal_value', decimal_value);
    if (decimal_value.length > decimal) {
      let new_decimal = decimal_value.slice(0, decimal);
      parts[1] = new_decimal;
    }
  }
  return parts.join('.');
};

const GetFilename = (value) => {
  const fileName = value ? value.split('/')[value.split('/').length - 1] : '';
  const decodedFilename = decodeURIComponent(fileName);

  return decodedFilename;
};

const isVipDateGreaterThanNow = (vipDate, vipDateUsa) => {
  // 현재 시간의 Unix 타임스탬프 값을 초 단위로 구합니다.
  const now = Math.floor(Date.now() / 1000);

  // vipDate와 vipDateUsa 각각의 Unix 타임스탬프 값을 초 단위로 가져옵니다.
  const unixTimeVipDate = Math.floor(vipDate);
  const unixTimeVipDateUsa = Math.floor(vipDateUsa);

  // vipDate와 vipDateUsa 중 하나라도 현재 시간보다 크다면 true를 반환하고, 그렇지 않으면 false를 반환합니다.
  return unixTimeVipDate > now || unixTimeVipDateUsa > now;
};

const getDayByUnixTime = (unixTime) => {
  // 현재 시간의 Unix 타임스탬프 값을 초 단위로 구합니다.
  const day = Math.floor(unixTime / 86400);
  return day;
};

const formatNumber = (number) => {
  // 숫자를 문자열로 변환
  let str = String(number);

  // 소수점 둘째자리까지 표현
  if (str.includes('.')) {
    const [integerPart, decimalPart] = str.split('.');
    if (decimalPart.length > 2) {
      str = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
  }

  // 1000단위로 콤마(,) 추가
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  str = str.replace(regex, ',');

  return str;
};

function masking(name) {
  // 이름의 길이가 1보다 작거나 같으면 원본 이름을 그대로 반환
  if (name.length <= 1) {
    return name;
  }

  // 이름의 두 번째 글자만 *로 마스킹하고 나머지는 그대로 출력
  let maskedName = name[0] + '*'.repeat(1) + name.substring(2);
  return maskedName;
}

const GetLabel = (type, value) => {
  if (type === 'region') {
    switch (value) {
      case 'all': {
        return '올인원';
      }
      case 'domestic': {
        return '한국';
      }
      case 'usa': {
        return '미국';
      }
      case 'vnm': {
        return '베트남';
      }
      case 'jpn': {
        return '일본';
      }
      case 'ida': {
        return '인도네시아';
      }
      default: {
        return value;
      }
    }
  }

  if (type === 'price_id') {
    switch (value) {
      case 'ALL_THREE_MONTH': {
        return '3개월';
      }
      case 'ALL_ONE_YEAR': {
        return '1년';
      }
      case 'ALL_TWO_YEAR': {
        return '2년';
      }
      case 'ALL_FIVE_YEAR': {
        return '5년';
      }
      case 'THREE_MONTH': {
        return '3개월';
      }
      case 'ONE_YEAR': {
        return '1년';
      }
      case 'TWO_YEAR': {
        return '2년';
      }
      case 'FIVE_YEAR': {
        return '5년';
      }
      case 'RECURRING_ONE_YEAR': {
        return '1년 구독';
      }
      case 'RECURRING_ALL_ONE_YEAR': {
        return '1년 구독';
      }
      default: {
        return value;
      }
    }
  }
};

const IsAvailablePayment = (array, requestId, serviceRegion) => {
  // 배열에서 요청된 값들이 있는지 확인
  const isRequestIdExist = array.some((item) => item.requestId === requestId);
  const isServiceRegionExist = array.some((item) => item.serviceRegion === serviceRegion);

  // 둘 다 존재하면 false, 아니면 true
  return !(isRequestIdExist && isServiceRegionExist);
};

const Form = {
  ObjectToParams: ObjectToParams,
  getYYYYMMDDByUnix: getYYYYMMDDByUnix,
  NumberFormatter: NumberFormatter,
  getYYYYMMDDHHMMssByUnix: getYYYYMMDDHHMMssByUnix,
  GetFilename: GetFilename,
  isVipDateGreaterThanNow: isVipDateGreaterThanNow,
  getDayByUnixTime: getDayByUnixTime,
  formatNumber: formatNumber,
  masking: masking,
  GetLabel: GetLabel,
  IsAvailablePayment: IsAvailablePayment,
};

export default Form;
